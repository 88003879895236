<template>
  <div :key="formKey" v-if="calculation" class="loanCalculator">
    <span>
      {{ t.customizeLoan ?? 't.customizeLoan' }}
    </span>
    <div>
      <div class="loanParameters">
        <div class="downPayment">
          {{ t.downPayment ?? 't.downPayment' }}
          <label class="input-currency">
            <input @keydown="acceptNumber" type="number" :class="errorDownPayment ? 'input-error' : ''" v-model.number="downPayment" data-test-id="downPayment" />
            <span class="currency">{{ getSymbol(currency) }}</span>
          </label>
          <button class="calculate" @click="handleDownPaymentChange(downPayment)">{{ t.calculate ?? 't.calculate' }}</button>
          <span class="error" v-if="errorDownPayment">
            <span><InfoIcon /></span>
            {{ errorDownPayment }}
          </span>
        </div>
        <div v-if="config.terms" class="paymentTerms">
          {{ t.paymentTerms ?? 't.paymentTerms' }}
          <div>
            <button v-for="term in config.terms" :key="term" @click="handleTermChange(term)" :data-test-id="term" :class="calculation.paymentTerms === term ? 'selected' : ''">
              {{ term }}<span>{{ t.months ?? 't.months' }}</span>
            </button>
          </div>
        </div>
        <div v-if="config.interestTypes?.length" class="interestType">
          {{ t.interestType ?? 't.interestType' }}
          <div>
            <button v-for="interestType in config.interestTypes" :key="interestType" @click="handleInterestTypeChange(interestType)" :data-test-id="interestType" :class="calculation.interestType === interestType ? 'selected' : ''">
              {{ t[interestType] ?? interestType }}
            </button>
          </div>
        </div>
        <button @click="saveCalculation" :disabled="errors?.length || calculation.downPayment !== downPayment" class="saveCalculationButton">
          {{ t.saveCalculation ?? 't.saveCalculation' }}
        </button>
        <div v-if="errors?.length" class="calculationErrors">
          <span v-for="error of errors" :key="error" class="calculationError">
            <InfoIcon />
            <span> {{ error.field + ' ' + error.errorCode + '\n' }}</span>
          </span>
        </div>
      </div>
      <!-- <div class="loanOverview" v-if="config?.provider?.name === 'santanderStandard'"> -->
      <div class="loanOverview" v-if="config?.provider?.name !== 'santanderFlex' && config?.provider?.name !== 'santanderBalloon'">
        <div class="summary">
          <span>{{ t.amountSummary ?? 't.amountSummary' }}</span>
          <h3>{{ calculation.monthlyPayment ? formatNumber(calculation.monthlyPayment, 0, currency) : '-' }} x {{ calculation.paymentTerms ?? '-' }} {{ t.months ?? 't.months' }}</h3>
        </div>
        <div class="details">
          <div>
            <span>{{ t.downPayment ?? 't.downPayment' }}</span>
            <span>{{ calculation.downPayment ? formatNumber(calculation.downPayment, 0, currency) : '-' }}</span>
          </div>
          <div>
            <span>{{ t.totalFinanceAmount ?? 't.totalFinanceAmount' }}</span>
            <span>{{ calculation.financedAmount ? formatNumber(calculation.financedAmount, 0, currency) : '-' }}</span>
          </div>
          <div>
            <span>{{ t.monthlyPayment ?? 't.monthlyPayment' }}</span>
            <span>{{ calculation.monthlyPayment ? formatNumber(calculation.monthlyPayment, 0, currency) : '-' }}</span>
          </div>
          <div>
            <span>{{ t.totalPayable ?? 't.totalPayable' }}</span>
            <span>{{ calculation.totalPayable ? formatNumber(calculation.totalPayable, 0, currency) : '-' }}</span>
          </div>
          <div class="rate">
            <div v-for="(rate, key) in rates" :key="key">
              <div>{{ t[rate?.key] ?? rate?.key }} {{ Number(rate?.value) }}%</div>
            </div>
          </div>
          <div class="disclaimer">
            <span v-html="t.disclaimer ?? 't.disclaimer'" />
            <p v-html="calculation.disclaimer ?? '-'" />
          </div>
          <div class="financingLogo" v-if="config.provider.logo">
            <span>{{ t.financingFrom ?? 't.financingFrom' }}</span>
            <img :src="imageUrl" />
          </div>
        </div>
      </div>
      <div class="loanOverview santanderFlex" v-else-if="config?.provider?.name === 'santanderFlex'">
        <div v-for="(option, index) in flexCalculator" :key="index">
          <div class="titlePlan">
            <div>{{ `${t.installmentPlan ?? 't.installmentPlan'} ${index + 1}` }}</div>
          </div>
          <div class="details">
            <div>
              <span>{{ t.monthlyPayment ?? 't.monthlyPayment' }}</span>
              <span>{{ formatNumber(option.importoRata, 0, currency) }}</span>
            </div>
            <div>
              <span>{{ t.paymentTerms ?? 't.paymentTerms' }}</span>
              <span>{{ paymentTermCalculatorFlex(option, index) }}</span>
            </div>
            <div>
              <span>{{ t.aopBeforeTax ?? 't.aopBeforeTax' }}</span>
              <span>{{ option?.tan ?? 0 }} %</span>
            </div>
            <div>
              <span>{{ t.apr ?? 't.apr' }}</span>
              <span>{{ option?.taeg ?? 0 }} %</span>
            </div>
          </div>
          <div class="afterMaxi" v-if="index !== flexCalculator.length - 1">
            <div class="after">{{ t.after ?? 't.after' }} {{ paymentTermCalculatorFlex(option, index) }}</div>
            <div class="maxiRata">
              <div>{{ t.maxiRata ?? 't.maxiRata' }}</div>
              <div>{{ formatNumber(option?.capitaleResiduo, 0, currency) }}</div>
            </div>
          </div>
          <div class="hr" />
        </div>
        <div class="disclaimer" v-html="calculation?.disclaimer" />
      </div>
      <div class="loanOverview santanderBalloon" v-else-if="config?.provider?.name === 'santanderBalloon'">
        <div class="titlePlan">
          <div>{{ t.titleBalloon ?? 't.titleBalloon' }}</div>
        </div>
        <div class="details">
          <div>
            <span>{{ t.monthlyPayment ?? 't.monthlyPayment' }}</span>
            <span>{{ calculation.monthlyPayment ? formatNumber(calculation.monthlyPayment, 0, currency) : '-' }}</span>
          </div>
          <div>
            <span>{{ t.paymentTerms ?? 't.paymentTerms' }}</span>
            <span>{{ calculation.paymentTerms }} {{ t.months ?? 't.months' }}</span>
          </div>
          <div>
            <span>{{ t.aopBeforeTax ?? 't.aopBeforeTax' }}</span>
            <span>{{ calculation.aopBeforeTax }}%</span>
          </div>
          <div>
            <span>{{ t.apr ?? 't.apr' }}</span>
            <span>{{ calculation.apr }}%</span>
          </div>
        </div>
        <div class="hr" />
        <div class="maxiBalloon">
          <div>{{ t[balloonCalculator.key] ?? `t.${balloonCalculator.key}` }}</div>
          <div>{{ formatNumber(balloonCalculator.value, 0, currency) }}</div>
        </div>
        <div class="disclaimer" v-html="calculation?.disclaimer" />
      </div>
      <Loader class="loading" v-if="isCalculating || loading" />
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { analyticsMixin } from '../../analytics.js'
import { langMixin } from '../lang'
import Loader from '@/components/Loader.ce.vue'
import InfoIcon from '../../assets/info-icon.svg'

export default {
  name: 'LoanCalculator',
  components: { Loader, InfoIcon },
  mixins: [SeezSdk.vueQueryMixin, langMixin('FINANCING_PROVIDER_COMPONENT_TRANSLATIONS'), analyticsMixin],
  props: {
    orderId: { type: String, required: true },
    financingOption: { type: Object, default: null },
    currency: { type: String, default: 'EUR' },
    rates: { type: Object, default: null },
    loading: { type: Boolean, default: false },
    providerLogo: { type: String, default: null }
  },
  emits: ['recalculate', 'saveCalculation'],
  data() {
    return {
      formKey: 1,
      isCalculating: false,
      calculation: { ...this.financingOption?.calculation },
      config: this.financingOption?.config,
      errors: this.financingOption?.errors,
      errorDownPayment: false,
      downPayment: this.financingOption?.calculation?.downPayment,
      flexCalculator: null,
      balloonCalculator: null
    }
  },
  computed: {
    calculationQuery() {
      return 'apr aopBeforeTax financedAmount loanAmount downPayment downPaymentPct totalPayable totalLoanCost paymentTerms monthlyPayment nominalInterestRate interestType interestRate disclaimer rates { key value } customAttributes { key value }'
    },
    imageUrl() {
      return `${import.meta.env.VITE_SEEZ_BASE_URL}${this.providerLogo ?? this.config.provider.logo}`
    }
  },
  watch: {
    calculation: {
      handler: function () {
        this.handleFlexBalloonValue()
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.debounceTimer)
  },
  mounted() {
    this.handleFlexBalloonValue()
  },
  methods: {
    handleFlexBalloonValue() {
      if (this.config?.provider?.name === 'santanderFlex') {
        const flexCalculator = this.calculation?.customAttributes?.[0]?.value
        this.flexCalculator = JSON.parse(flexCalculator)
      } else if (this.config?.provider?.name === 'santanderBalloon') {
        this.balloonCalculator = this?.calculation?.customAttributes?.[0]
      }
    },
    paymentTermCalculatorFlex(option, index) {
      const paymentTerm = index === 0 ? option.rataA + option.rataDa : option.rataA - option.rataDa
      return `${paymentTerm} ${this.t.months}`
    },
    checkDownPayment(downPayment) {
      const downPaymentValue = this.financingOption?.config?.downPayment
      if (downPayment == 0) {
        this.errorDownPayment = this.t.downPaymentNotZero
        return true
      }
      if (downPayment < downPaymentValue?.min) {
        this.errorDownPayment = this.t.minimumDownPayment.replace('{{value}}', `${this.formatNumber(downPaymentValue?.min)} ${this.getSymbol(this.currency)}`)
        return true
      } else if (downPayment > downPaymentValue?.max) {
        this.errorDownPayment = this.t.maximumDownPayment.replace('{{value}}', `${this.formatNumber(downPaymentValue?.max)} ${this.getSymbol(this.currency)}`)
        return true
      }
      this.errorDownPayment = false
      this.calculation.downPayment = downPayment
    },
    handleDownPaymentChange(downPayment) {
      if (!this.checkDownPayment(downPayment)) {
        this.loanCalculation()
      }
    },
    handleInterestTypeChange(interestType) {
      this.calculation.interestType = interestType
      if (this.downPayment !== this.calculation.downPayment) {
        this.handleDownPaymentChange(this.downPayment)
      } else {
        this.loanCalculation()
      }
    },
    handleTermChange(term) {
      this.calculation.paymentTerms = term
      if (this.downPayment !== this.calculation.downPayment) {
        this.handleDownPaymentChange(this.downPayment)
      } else {
        this.loanCalculation()
      }
    },
    acceptNumber(evt) {
      return ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
    },
    async saveCalculation() {
      this.$emit('saveCalculation', this.calculation)
    },
    async loanCalculation() {
      const financingInput = {
        // selectedProvider: provider ?? this.config?.provider?.name,
        selectedProvider: this.config?.provider?.name,
        downPayment: Number(this.calculation.downPayment),
        paymentTerms: this.calculation.paymentTerms,
        interestType: !this.order?.payment?.financing?.config?.interestTypes?.length ? undefined : this.calculation.interestType
      }
      try {
        this.isCalculating = true

        const input = { input: financingInput }

        const query = `
        query loanCalculations($input: LoanCalculationInput) {
          loanCalculations(
            orderId: ${this.orderId}
            input: $input
          ) {
            selected
            config { interestTypes terms downPayment { min max default } provider { name logo }}
            userInput { selectedProvider downPayment interestType paymentTerms }
            calculation {${this.calculationQuery}}
            errors { field errorCode }
          }
        }
        `

        const { loanCalculations } = await this.queryApi(query, input)
        const loanCalculation = loanCalculations?.find(lc => lc.selected === true)
        if (loanCalculation.calculation) this.calculation = loanCalculation.calculation
        this.config = loanCalculation.config
        this.errors = loanCalculation.errors
        // this.formKey += 1
        // this.orderFinancing.redirectUrl = loanCalculation?.redirectUrl
        // this.orderFinancing.selected = loanCalculation?.selected
        // this.orderFinancing.userInput = loanCalculation?.userInput
        // this.orderFinancing.config = loanCalculation?.config
        // this.orderFinancing.errors = loanCalculation?.errors
      } catch (e) {
        console.error(e)
      } finally {
        this.isCalculating = false
      }
    }
  }
}
</script>

<style lang="scss">
.loanCalculator {
  position: relative;
  max-width: 720px;
  text-align: start;

  .calculationErrors {
    color: #d02e26;

    .calculationError {
      display: flex;
      flex-direction: row;
      font-size: 12px;
      gap: 0.5rem;
      padding: 0.2rem;
    }

    svg {
      path {
        fill: #d02e26;
      }
    }
  }

  > div {
    @media (max-width: 870px) {
      grid-template-columns: auto !important;
    }
  }
  .rate {
    margin-top: 12px;
    justify-content: center !important;
    > div {
      text-align: center;
      border-radius: 20px;
      border: 1px solid #c6c9ca;
      margin-right: 4px;
      margin-top: 4px;
      padding: 4px 12px;
      font-weight: 700;
      font-size: 1.1rem;
    }
  }
  .downPayment .input-currency {
    flex-direction: row !important;
    margin-top: 16px;
    width: 100%;
    align-items: center;
    position: relative;
    .input-error {
      border: 0.5px solid var(--red400) !important;
    }
    input {
      width: 100%;
    }
  }
  .downPayment .error {
    font-size: 12px;
    color: #d02e26;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 0.5rem;

    svg {
      path {
        fill: #d02e26;
      }
    }
  }
}
.loading {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
</style>
