<template>
  <div class="paymentOptions">
    <div>
      <span>{{ t.selectPaymentMethod ?? 't.selectPaymentMethod' }} </span>
      <div class="selector">
        <div v-for="paymentMethod in paymentMethods" :key="paymentMethod.type">
          <input type="radio" :id="paymentMethod.type" :value="paymentMethod.type" v-model="selectedPaymentMethod" />
          <label :for="paymentMethod.enabled ? paymentMethod.type : null" :class="paymentMethod.enabled ? '' : 'disabled'"> {{ t[paymentMethod.name] ?? paymentMethod.name }} </label>
        </div>
        <Loader v-if="loading" />
      </div>
    </div>
    <div v-if="error" class="errorWrapper">
      <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 1.625C5.24219 1.625 3.64844 2.5625 2.75781 4.0625C1.89062 5.58594 1.89062 7.4375 2.75781 8.9375C3.64844 10.4609 5.24219 11.375 7 11.375C8.73438 11.375 10.3281 10.4609 11.2188 8.9375C12.0859 7.4375 12.0859 5.58594 11.2188 4.0625C10.3281 2.5625 8.73438 1.625 7 1.625ZM7 12.5C4.84375 12.5 2.875 11.375 1.79688 9.5C0.71875 7.64844 0.71875 5.375 1.79688 3.5C2.875 1.64844 4.84375 0.5 7 0.5C9.13281 0.5 11.1016 1.64844 12.1797 3.5C13.2578 5.375 13.2578 7.64844 12.1797 9.5C11.1016 11.375 9.13281 12.5 7 12.5ZM6.0625 8.375H6.625V6.875H6.0625C5.73438 6.875 5.5 6.64062 5.5 6.3125C5.5 6.00781 5.73438 5.75 6.0625 5.75H7.1875C7.49219 5.75 7.75 6.00781 7.75 6.3125V8.375H7.9375C8.24219 8.375 8.5 8.63281 8.5 8.9375C8.5 9.26562 8.24219 9.5 7.9375 9.5H6.0625C5.73438 9.5 5.5 9.26562 5.5 8.9375C5.5 8.63281 5.73438 8.375 6.0625 8.375ZM7 5C6.57812 5 6.25 4.67188 6.25 4.25C6.25 3.85156 6.57812 3.5 7 3.5C7.39844 3.5 7.75 3.85156 7.75 4.25C7.75 4.67188 7.39844 5 7 5Z" fill="#D02E26" />
      </svg>
      <p>{{ t.errorNationalityMissing || 't.errorNationalityMissing' }}</p>
    </div>
    <div class="paymentMethods">
      <div v-if="selectedPaymentMethod === 'financing'">
        <FinancingProvider :key="formKey" :order-id="order.id" :open-loan-calculator="openLoanCalculator" :selected-financing-option="selectedFinancingOption" :all-financing-options="allFinancingOptions" :currency="currency" :loading="loading" :rates="rates" @save-calculation="onSaveCalculation" :provider-logo="order.listing?.owner?.financing?.logo" />
        <InsuranceOptions v-if="!loading && order?.insuranceData" :key="formKey" :order-id="order.id" :insurance-data="order?.insuranceData" @insurance-updated="insuranceUpdated" />
      </div>
      <div v-else-if="selectedPaymentMethod === 'bankTransfer'">
        <div class="bankTransfer">
          <div class="total" v-if="pricingBreakdown?.intitialTransferableAmount?.total">
            <h3>{{ t.totalPrice ?? 't.totalPrice' }}</h3>
            <h3>{{ formatNumber(pricingBreakdown?.intitialTransferableAmount?.total, 0, currency) ?? 't.TBD' }}</h3>
          </div>
          <div v-if="pricingBreakdown.intitialTransferableAmount?.products?.length" class="details">
            <div v-for="product in pricingBreakdown.intitialTransferableAmount.products.filter(p => p.price !== null)" :key="product.key">
              <span>{{ product.name }}</span>
              <span>{{ formatNumber(product.price, 0, currency) }}</span>
            </div>
          </div>
        </div>
        <InsuranceOptions v-if="!loading && order?.insuranceData" :key="formKey" :order-id="order.id" :insurance-data="order?.insuranceData" @insurance-updated="insuranceUpdated" />
      </div>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../sdk.js'
import { langMixin } from '../../lang'
import FinancingProvider from '../../FinancingProvider/FinancingProvider.ce.vue'
import InsuranceOptions from './InsuranceOptions.ce.vue'
import Loader from '../../Loader.ce.vue'
import toastInstance from '@/components/toastInstance.js'

export default {
  name: 'PaymentOptions',
  components: { FinancingProvider, InsuranceOptions, Loader },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true }
  },
  emits: ['financingOptionsUpdated', 'paymentUpdated', 'insuranceUpdated'],
  data() {
    return {
      formKey: 1,
      error: false,
      loading: false,
      openLoanCalculator: false,
      selectedPaymentMethod: this.order?.payment?.type ?? ''
    }
  },
  computed: {
    calculationQuery() {
      return 'apr aopBeforeTax financedAmount loanAmount downPayment downPaymentPct totalPayable totalLoanCost paymentTerms monthlyPayment nominalInterestRate interestType interestRate disclaimer rates { key value } customAttributes { key value }'
    },
    paymentMethodQuery() {
      return 'key name breakdowns {key name products {key name price description} total}'
    },
    paymentMethods() {
      return (
        this.order?.listing?.owner?.paymentOptions ?? [
          { name: this.t.financing ?? 't.financing', type: 'financing', enabled: false },
          { name: this.t.bankTransfer ?? 't.bankTransfer', type: 'bankTransfer', enabled: true }
        ]
      )
    },
    pricingBreakdown() {
      const breakdowns = {
        financing: {
          financeableProducts: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'financeableProducts'),
          intitialTransferableAmount: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'financingInitialTransferableAmount'),
          finalTransferableAmount: this.order?.pricing?.financing?.breakdowns?.find(bd => bd.key === 'financingFinalTransferableAmount')
        },
        bankTransfer: {
          intitialTransferableAmount: this.order?.pricing?.cashPayment?.breakdowns?.find(bd => bd.key === 'cashPaymentInitialTransferableAmount'),
          finalTransferableAmount: this.order?.pricing?.cashPayment?.breakdowns?.find(bd => bd.key === 'cashPaymentFinalTransferableAmount')
        }
      }
      return breakdowns[this.order?.payment?.type] ?? []
    },
    currency() {
      return this.order?.pricing?.currency
    },
    rates() {
      return this.order?.payment?.financing?.calculation?.rates
    },
    insuranceData() {
      return this.order?.insuranceData
    },
    selectedFinancingOption() {
      return this.order?.payment?.financing
    },
    allFinancingOptions() {
      return this.order.payment?.financingOptions?.filter(e => !!e.calculation)
    }
  },
  watch: {
    selectedPaymentMethod: {
      handler: function () {
        const input = {
          id: this.order.id,
          appState: 'payment',
          validateSteps: ['payment'],
          payment: {
            type: this.selectedPaymentMethod
          }
        }
        if (this.selectedPaymentMethod === 'financing') {
          input.payment.financing = {}
        }
        this.updateOrder(input)
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    handleClickOutside(event) {
      if (this.$el === null) return

      let isInside = false

      for (const child of this.$el.children) {
        const b = child.getBoundingClientRect()
        if (event.clientX >= b.left && event.clientX < b.right && event.clientY >= b.top && event.clientY < b.bottom) {
          isInside = true
          break
        }
      }

      if (!isInside && this.error) {
        this.error = false
      }
    },
    toastInstance() {
      return toastInstance
    },
    insuranceUpdated(input) {
      this.formKey += 1
      this.$emit('insuranceUpdated', input)
    },
    getPaymentInput(calculationInput, provider) {
      return {
        id: this.order.id,
        appState: 'payment',
        validateSteps: ['payment'],
        payment: {
          type: 'financing',
          financing: {
            selectedProvider: provider ?? this.selectedFinancingOption?.config?.provider?.name,
            downPayment: Number(calculationInput.downPayment),
            paymentTerms: calculationInput.term ?? calculationInput.paymentTerms,
            interestType: !this.order?.payment?.financing?.config?.interestTypes?.length ? undefined : calculationInput.interestType
          }
        }
      }
    },
    onSaveCalculation(calculationInput, provider) {
      const input = this.getPaymentInput(calculationInput, provider)
      this.updateOrder(input)
    },
    async updateOrder(input) {
      this.error = false

      try {
        this.loading = true

        const mutation = `
          mutation updateOrder(
            $id: ID!,
            $payment: UpdatePaymentInput,
            $appState: AppStateValue,
            $validateSteps: [AppStateValue]) {
            updateOrder(
              orderId: $id,
              payment: $payment,
              appState: $appState,
              validateSteps: $validateSteps) {
                id
                appState { current steps { name state errors }}
                payment {
                  type
                  state
                  bankTransfer { state }
                  financing {
                    state
                    selected
                    redirectUrl
                    errors { field errorCode }
                    userInput { selectedProvider downPayment interestType paymentTerms }
                    config { interestTypes terms downPayment { min max default } provider { name logo }}
                    calculation {${this.calculationQuery}}
                  }
                  financingOptions {
                    state
                    selected
                    redirectUrl
                    errors { field errorCode }
                    userInput { selectedProvider downPayment interestType paymentTerms }
                    config { interestTypes terms downPayment { min max default } provider { name logo }}
                    calculation {${this.calculationQuery}}
                  }
                }
                insuranceData { provider multiple selectedProvider selectedOptionIds options { id name parentId description amount currency price { amount currency min max text } group link }}
                pricing { financing {${this.paymentMethodQuery}} cashPayment {${this.paymentMethodQuery}} externalPaymentsTotal adminFeesTotal adminFees { name amount currency requiredFor } currency discount vehiclePrice addonsPrice valueAddedServicesPrice deliveryPrice licensePlatePrice tradeInAmount tradeInOffer tradeInAdminFee tradeInReserveAmount tradeInBalance tradeInOfferGross tradeInFinancing tradeInReturnToCustomer subtotal total final }
            }
          }
        `

        let inputOrder = { ...input }

        if (inputOrder?.delivery?.location) {
          inputOrder.locationId = inputOrder.delivery.location.id
          delete inputOrder.delivery.location
        }
        const { updateOrder } = await this.queryApi(mutation, inputOrder)
        const errors = updateOrder.payment?.financing?.errors || []

        if (errors.length > 0) {
          this.error = errors.some(i => i.field === 'nationality')
        }

        this.formKey += 1
        this.$emit('paymentUpdated', updateOrder)
      } catch (e) {
        if (e.message.includes('nationality')) {
          this.error = true
          return
        }
        console.error('update order error', e, this.toastInstance())
        this.toastInstance().get().showToast({
          type: 'error',
          content: e.message
        })
      } finally {
        this.openLoanCalculator = false
        this.loading = false
      }
    }
  }
}
</script>
