<template>
  <div class="customerInfo">
    <div>
      <span v-if="formFields.coOwner">{{ t.howManyOwners ?? 't.howManyOwners' }}</span>
      <div v-if="formFields.coOwner" class="selector">
        <input type="radio" id="singleOwner" value="singleOwner" v-model="numberOfOwners" :disabled="loading" />
        <label for="singleOwner"> {{ t.singleOwner ?? 't.singleOwner' }} </label>
        <input type="radio" id="dualOwner" value="dualOwner" v-model="numberOfOwners" :disabled="loading" />
        <label for="dualOwner"> {{ t.dualOwner ?? 't.dualOwner' }} </label>
        <Loader v-if="loading" />
      </div>
    </div>
    <div class="singleOwner">
      <span>{{ t.singleOwnerDetails ?? 't.singleOwnerDetails' }}</span>
      <FormTemplate :preloaded-data="customerInfoModel" :fields="formFields.customer" :errors="appState.state === 'pending' ? appState?.errors : null" @updated="updateCustomerInput" />
      <span v-if="formFields.customerAddressLookup" class="formAddressLabel">{{ t.address ?? 't.address' }}</span>
      <FormTemplateAddress v-if="formFields.customerAddressLookup" :order-id="order.id" :preloaded-data="customerInfoModel?.address" :address-lookup-field="formFields.customerAddressLookup" @updated="updateCustomerAddressInput" />
    </div>
    <div v-if="numberOfOwners === 'dualOwner' && formFields.coOwner" class="dualOwner">
      <span>{{ t.dualOwnerDetails ?? 't.dualOwnerDetails' }}</span>
      <FormTemplate :preloaded-data="coOwnerInfoModel" :fields="formFields.coOwner" :errors="appState.state === 'pending' ? appState?.errors : null" @updated="updateCoOwnerInput" />
      <span v-if="formFields.coOwnerAddressLookup" class="formAddressLabel">{{ t.address ?? 't.address' }}</span>
      <FormTemplateAddress v-if="formFields.coOwnerAddressLookup" :order-id="order.id" :preloaded-data="dataModecoOwnerInfoModel?.address" :address-lookup-field="formFields.coOwnerAddressLookup" @updated="updateCoOwnerAddressInput" />
    </div>
    <div class="saveButton">
      <Loader v-if="loading" />
      <button v-else @click="updateCustomerInfo">
        {{ t.saveCustomerInfo ?? 't.saveCustomerInfo' }}
      </button>
    </div>
  </div>
</template>

<script>
import SeezSdk from '../../../sdk.js'
import { langMixin } from '../../lang'
import { mergeObjects } from '../../../logic.js'
import FormTemplate from '@/components/FormTemplate/FormTemplate.ce.vue'
import FormTemplateAddress from '@/components/FormTemplateAddress/FormTemplateAddress.ce.vue'
import Loader from '../../Loader.ce.vue'

const STANDARD_PAYLOAD = ['firstName', 'middleName', 'lastName', 'email', 'phone', 'nationalID', 'age', 'address', 'insurance', 'insuranceDetails', 'dateOfBirth', 'customAttributes', 'coOwnerInfo', 'id', 'streetName', 'streetNumber', 'door', 'floor', 'buildingNumber', 'street', 'street2', 'postalCode', 'province', 'city', 'zone', 'state', 'country', 'dawaUrl', 'data']

export default {
  name: 'AdditionalCustomerInfo',
  components: { FormTemplate, FormTemplateAddress, Loader },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    order: { type: Object, required: true }
  },
  emits: ['onAppStateUpdate', 'onCustomerInfoUpdate'],
  data() {
    let customerDataModel = this.order.customerInfo
    let coOwnerDataModel = this.order.coOwnerInfo

    const customerFields = this.order.listing.owner.layoutTemplates.find(sc => sc.name === 'postSubmitCustomerInfo')?.fields
    let customerAddressLookup = customerFields?.find(f => f.name === 'address')

    if (customerAddressLookup?.type !== 'addressLookup') {
      customerFields.push(...customerAddressLookup.fields.map(f => ({ ...f, parent: 'address' })))
      customerDataModel = this.formatData(customerDataModel, customerFields)
      customerAddressLookup = null
    }

    const coOwnerFields = customerFields.find(f => f.name === 'coOwnerInfo')?.fields
    let coOwnerAddressLookup = coOwnerFields?.find(f => f.name === 'address')
    if (coOwnerFields && coOwnerAddressLookup && coOwnerAddressLookup?.type !== 'addressLookup') {
      coOwnerFields.push(...coOwnerAddressLookup.fields.map(f => ({ ...f, parent: 'address' })))
      coOwnerDataModel = this.formatData(coOwnerDataModel, coOwnerFields)
      coOwnerAddressLookup = null
    }

    return {
      loading: false,
      formFields: {
        customer: customerFields,
        customerAddressLookup: customerAddressLookup,
        coOwner: coOwnerFields,
        coOwnerAddressLookup: coOwnerAddressLookup
      },
      customerInfoModel: customerDataModel,
      coOwnerInfoModel: coOwnerDataModel,
      numberOfOwners: this.order.coOwnerInfo !== null ? 'dualOwner' : 'singleOwner',
      input: {
        customerInfo: { ...this.order.customerInfo },
        coOwnerInfo: this.order.coOwnerInfo ? { ...this.order.coOwnerInfo } : null
      }
    }
  },
  computed: {
    appState() {
      let state = 'completed'
      let errors = []

      for (const field of this.formFields.customer) {
        if (field.required && !this.customerInfoModel[field.name]) {
          state = 'pending'
          errors.push(field.name)
        }
      }
      if (this.numberOfOwners === 'dualOwner') {
        for (const field of this.formFields.coOwner) {
          if (field.required && !this.coOwnerInfoModel[field.name]) {
            state = 'pending'
            errors.push[`coOwner.${field.name}`]
          }
        }
      }

      const appState = {
        state: state,
        errors: errors
      }

      this.$emit('onAppStateUpdate', appState)

      return appState
    }
  },
  watch: {
    numberOfOwners: {
      handler: function () {
        this.updateCustomerInfo()
      }
    }
  },
  methods: {
    formatData(data, fields) {
      let formattedDataModel = {}
      if (data) {
        for (const field of fields) {
          if (!STANDARD_PAYLOAD.includes(field.name) && data.customAttributes) {
            formattedDataModel[field.name] = data.customAttributes.find(ca => ca.key === field.name)?.value
          } else if (field.parent) {
            formattedDataModel[field.name] = formattedDataModel[field.name] ? formattedDataModel[field.name] : data[field.parent][field.name]
            if (field.type === 'lookup' && data[field.parent][field.name] && field.function && window[field.function]) {
              formattedDataModel = window[field.function](formattedDataModel)
            }
          } else {
            formattedDataModel[field.name] = data[field.name]
          }
        }
      }
      return formattedDataModel
    },
    updateCustomerInput(formInput) {
      let customerFormInput = {}
      let addressFormInput
      let customAttributes
      for (const field of this.formFields.customer) {
        if (field.parent) {
          if (!addressFormInput) {
            addressFormInput = {}
          }
          let newObj = {}
          newObj[field.name] = formInput[field.name]
          addressFormInput[field.parent] = { ...addressFormInput[field.parent], ...newObj }
        } else {
          if (STANDARD_PAYLOAD.includes(field.name)) {
            customerFormInput[field.name] = formInput[field.name]
          } else {
            if (!customAttributes) {
              customAttributes = []
            }
            customAttributes.push({ key: field.name, value: formInput[field.name] })
          }
        }
      }

      this.input.customerInfo = mergeObjects(this.input.customerInfo, { ...customerFormInput })

      if (customAttributes) {
        this.input.customerInfo.customAttributes = customAttributes.map(ca => {
          return { key: ca.key, value: ca.value }
        })
      }

      if (addressFormInput) {
        this.updateCustomerAddressInput({ ...addressFormInput.address })
      }
    },
    updateCustomerAddressInput(formInput) {
      this.input.customerInfo.address = mergeObjects(this.input.customerInfo.address, { ...formInput })
    },
    updateCoOwnerInput(formInput) {
      let coOwnerFormInput = {}
      let addressFormInput
      let customAttributes
      for (const field of this.formFields.coOwner) {
        if (field.parent) {
          if (!addressFormInput) {
            addressFormInput = {}
          }
          let newObj = {}
          newObj[field.name] = formInput[field.name]
          addressFormInput[field.parent] = { ...addressFormInput[field.parent], ...newObj }
        } else {
          if (STANDARD_PAYLOAD.includes(field.name)) {
            coOwnerFormInput[field.name] = formInput[field.name]
          } else {
            if (!customAttributes) {
              customAttributes = []
            }
            customAttributes.push({ key: field.name, value: formInput[field.name] })
          }
        }
      }

      this.input.coOwnerInfo = mergeObjects(this.input.coOwnerInfo, { ...coOwnerFormInput })

      if (customAttributes) {
        this.input.coOwnerInfo.customAttributes = customAttributes.map(ca => {
          return { key: ca.key, value: ca.value }
        })
      }

      if (addressFormInput) {
        this.updateCoOwnerAddressInput({ ...addressFormInput.address })
      }
    },
    updateCoOwnerAddressInput(formInput) {
      this.input.coOwnerInfo.address = mergeObjects(this.input.coOwnerInfo.address, { ...formInput })
    },
    async updateCustomerInfo() {
      this.loading = true

      const payload = {
        id: this.order.id,
        customerInfo: this.input.customerInfo,
        coOwnerInfo: this.numberOfOwners === 'dualOwner' ? this.input.coOwnerInfo : null
      }

      try {
        const mutation = `
          mutation updateOrder(
            $id: ID!,
            $customerInfo: CustomerInfoInput,
            $coOwnerInfo: CustomerInfoInput,
            $validateSteps: [AppStateValue],
            ) {
            updateOrder(
              orderId: $id,
              customerInfo: $customerInfo,
              coOwnerInfo: $coOwnerInfo,
              validateSteps: $validateSteps) {
                customerInfo { firstName middleName lastName email phone insurance nationalID dateOfBirth customAttributes { key value } address { street street2 postalCode province city state country streetName streetNumber floor door zone buildingNumber }}
                coOwnerInfo { firstName middleName lastName email phone insurance nationalID dateOfBirth customAttributes { key value } address { street street2 postalCode province city state country streetName streetNumber floor door zone buildingNumber }}
              }
            }
        `
        const { updateOrder } = await this.queryApi(mutation, payload)
        if (updateOrder) {
          this.customerInfoModel = this.formatData(updateOrder.customerInfo, this.formFields.customer)
          if (updateOrder.coOwnerInfo) {
            this.coOwnerInfoModel = this.formatData(updateOrder.coOwnerInfo, this.formFields.coOwner)
          }
        }
        this.$emit('onCustomerInfoUpdate', updateOrder)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.customerInfo {
  @media screen and (max-width: 36rem) {
    .formTemplate .formContainer {
      display: flex;
      flex-direction: column;
    }
    .dualOwner {
      margin-top: 1rem;
    }
  }

  .singleOwner .formAddressLabel,
  .dualOwner .formAddressLabel {
    display: none !important;
  }
}
</style>
