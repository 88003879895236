import { defineCustomElement } from 'vue'

function getCustomStyles(tagName) {
  const name = tagName.toLowerCase()
  const re = new RegExp('\\b' + name + '\\b', 'gm')
  const styles = []

  for (const ss of document.styleSheets) {
    try {
      for (const rule of ss.cssRules) {
        if (rule.cssText.match(re)) {
          if (rule instanceof CSSMediaRule) {
            //is media query
            let i = 0
            while (i < rule.cssRules.length) {
              //remove unrelated styles inside media query
              if (rule.cssRules[i].cssText.match(re) == null) rule.deleteRule(i)
              else i++
            }
          }
          if (rule.conditionText != null) styles.push(rule.cssText.replace(name, ''))
          else
            styles.push(
              ...rule.selectorText
                .split(',')
                .filter(x => x.includes(name))
                .map(x => rule.cssText.replace(rule.selectorText, x).replace(name, ''))
            )
        }
      }
    } catch (error) {
      //ignore: there seems to be some CORS issues
    }
  }
  return styles
}

function stylerMixin(tagName) {
  return {
    mounted() {
      const styles = getCustomStyles(tagName)
      if (styles.length > 0) {
        const styleSheet = document.createElement('style')
        styleSheet.innerHTML = styles.join(' ')
        styleSheet.setAttribute('data-custom', 'seez')
        this.$el.parentNode.insertBefore(styleSheet, this.$el)
      } else if (this.loadDefaultStyles === false) {
        // checking if the component has default styles property
        this.loadDefaultStyles = true
      }
    }
  }
}

function registerComponent(mainComponent, tagName, ...additionalStyles) {
  if (mainComponent.styles == null) mainComponent.styles = []
  mainComponent.styles.push(...additionalStyles.flatMap(x => x.styles))
  if (mainComponent.mixins == null) mainComponent.mixins = []
  mainComponent.mixins.push(stylerMixin(tagName))
  customElements.define(tagName, defineCustomElement(mainComponent))
}

import SeezSdk from './sdk.js'
window.seezSdk = new SeezSdk()
window.onload = () => window.seezSdk.injectSeezOnlineBuying()

import ListingCard from './components/ListingCard2.ce.vue'
import SearchDropdown from './components/SearchDropdown.ce.vue'
import EmptySearch from './components/EmptySearch.ce.vue'
import SavedSearch from './components/SavedSearches/SavedSearch.ce.vue'
import MultiSelect from './components/MultiSelect.ce.vue'
import FilterPanel from './components/Search/FilterPanel.ce.vue'
import ResultsPanel from './components/Search/ResultsPanel.ce.vue'
import OrderCard from './components/Orders/OrderCard.ce.vue'
import TradeinCard from './components/Tradeins/TradeinCard.ce.vue'
import InputCode from './components/Session/InputCode.ce.vue'
import Loader from './components/Loader.ce.vue'
import Toggle from './components/Toggle.ce.vue'
import TooltipComponent from './components/Tooltip.ce.vue'
import DateTimeSlot from './components/DateTimeSlot.ce.vue'

import Modal from './components/Modal.ce.vue'
registerComponent(Modal, 'seez-sdk-modal')

import FormTemplate from './components/FormTemplate/FormTemplate.ce.vue'
registerComponent(FormTemplate, 'seez-sdk-form-template', SearchDropdown, DateTimeSlot)

import FormTemplateAddress from './components/FormTemplateAddress/FormTemplateAddress.ce.vue'
registerComponent(FormTemplateAddress, 'seez-sdk-form-template-address')

import LoginForm from './components/Session/LoginForm.ce.vue'
registerComponent(LoginForm, 'seez-sdk-login-form', InputCode)

import ActiveListingCard from './components/ActiveListingCard.ce.vue'
registerComponent(ActiveListingCard, 'seez-sdk-listing-card', ListingCard, Modal)

import TradeInDrawer from './components/Tradeins/TradeInDrawer.ce.vue'
registerComponent(TradeInDrawer, 'seez-sdk-trade-in', MultiSelect, Loader)

import TradeInUploadHelp from './components/Tradeins/TradeinUploadHelp.ce.vue'
registerComponent(TradeInUploadHelp, 'seez-sdk-tradein-help', Modal)

import Calculator from './components/Calculator/Calculator.ce.vue'
registerComponent(Calculator, 'seez-sdk-calculator', Loader)

import FinancingProvider from './components/FinancingProvider/FinancingProvider.ce.vue'
import LoanCalculator from './components/FinancingProvider/LoanCalculator.ce.vue'
registerComponent(FinancingProvider, 'seez-sdk-financing-provider', Loader, LoanCalculator)

import Carousel from './components/Carousel/Carousel2.ce.vue'
registerComponent(Carousel, 'seez-sdk-carousel', Loader, ListingCard, Modal)

import Favorites from './components/Favorites/Favorites.ce.vue'
registerComponent(Favorites, 'seez-sdk-favorites', Loader, ListingCard, EmptySearch, MultiSelect, Modal)

import Orders from './components/Orders/Orders.ce.vue'
registerComponent(Orders, 'seez-sdk-orders', Loader, OrderCard)

import ActiveOrderCancellation from './components/Orders/ActiveOrderCancellation.ce.vue'
registerComponent(ActiveOrderCancellation, 'seez-sdk-active-order-cancellation', Loader, Modal)

import UnavailableListingModal from './components/Orders/UnavailableListingModal.ce.vue'
registerComponent(UnavailableListingModal, 'seez-sdk-unavailable-listing-modal', Loader, Modal, Carousel)

import SavedSearches from './components/SavedSearches/SavedSearches.ce.vue'
registerComponent(SavedSearches, 'seez-sdk-saved-searches', Loader, ListingCard, SavedSearch, Modal)

import Search from './components/Search/Search.ce.vue'
registerComponent(Search, 'seez-sdk-search', FilterPanel, Loader, MultiSelect, ResultsPanel, ListingCard, EmptySearch, Modal)

import LogoutModal from './components/Session/LogoutModal.ce.vue'
registerComponent(LogoutModal, 'seez-sdk-logout', Modal)

import UserProfile from './components/Session/Profile.ce.vue'
registerComponent(UserProfile, 'seez-sdk-user-profile', Loader, Toggle)

import ImagesCarousel from './components/ListingDetails/ImagesCarousel.ce.vue'
import ListingDetails from './components/ListingDetails/ListingDetails.ce.vue'
import Collapsable from './components/ListingDetails/Collapsable.ce.vue'
import SpecsGrid from './components/ListingDetails/SpecsGrid.ce.vue'
import ListingCalculator from './components/ListingDetails/Calculator.ce.vue'

registerComponent(ListingDetails, 'seez-sdk-listing-details', Loader, ImagesCarousel, Collapsable, SpecsGrid, ListingCalculator, Carousel, ListingCard, Modal, SearchDropdown, TooltipComponent, Calculator)

import TradeInForm from './components/TradeInForm/TradeInForm.ce.vue'
registerComponent(TradeInForm, 'seez-sdk-trade-in-form', Loader, FormTemplate)

import LeadForm from './components/Lead/LeadForm.ce.vue'
registerComponent(LeadForm, 'seez-sdk-lead-form', Loader, MultiSelect)

import TestDriveModal from './components/TestDrive/TestDriveModal.ce.vue'
registerComponent(TestDriveModal, 'seez-sdk-test-drive-modal', Loader, Modal, MultiSelect)

import TestDriveForm from './components/TestDrive/TestDriveForm.ce.vue'
registerComponent(TestDriveForm, 'seez-sdk-test-drive-form', Loader, MultiSelect)

import ChatComponent from './components/Chat/ChatComponent.ce.vue'
import ChatMessageList from './components/Chat/ChatMessageList.ce.vue'
import FAQPills from './components/Chat/FAQPills.ce.vue'
import UserAvatar from './components/Chat/UserAvatar.ce.vue'
import ChatMessage from './components/Chat/ChatMessage.ce.vue'
import ChatInput from './components/Chat/ChatInput.ce.vue'
import ChatLoading from './components/Chat/ChatLoading.ce.vue'
import ChatModal from './components/Chat/ChatModal.ce.vue'
import LinkCards from './components/Chat/LinkCards.ce.vue'
import ClearChat from './components/Chat/ClearChat.ce.vue'
import Tooltip from './components/Chat/Tooltip.ce.vue'
import ListingSelector from './components/DynamicInquiryForm/ListingSelector.ce.vue'
import Success from './components/DynamicInquiryForm/Success.ce.vue'

import DynamicInquiryForm from './components/DynamicInquiryForm/DynamicInquiryForm.ce.vue'
registerComponent(DynamicInquiryForm, 'seez-sdk-dynamic-inquiry-form', ListingSelector, Loader, FormTemplate, Success)

import ContactButton from './components/Chat/ContactButton.ce.vue'
registerComponent(ContactButton, 'seez-sdk-contact-button')

registerComponent(ClearChat, 'seez-sdk-clear-chat', Loader)
registerComponent(LinkCards, 'seez-sdk-link-cards', Loader)
registerComponent(ChatComponent, 'seez-sdk-chat', ChatMessageList, UserAvatar, ChatMessage, ChatInput, ChatLoading)
registerComponent(ChatModal, 'seez-sdk-seezar-modal', ChatComponent, ChatMessageList, UserAvatar, ChatMessage, ChatInput, ChatLoading, ContactButton, Tooltip, DynamicInquiryForm, FAQPills)

import ComparisonTable from './components/ComparisonTable/ComparisonTable.ce.vue'
registerComponent(ComparisonTable, 'seez-sdk-comparison-table', Loader)

import LocationCards from './components/Chat/LocationCards.ce.vue'
registerComponent(LocationCards, 'seez-sdk-location-cards')

import TradeInOffer from './components/Tradeins/TradeinOffer.ce.vue'
registerComponent(TradeInOffer, 'seez-sdk-tradein-offer', Loader, Modal)

import TradeinDetails from './components/Tradeins/TradeinDetails.ce.vue'
import Tradeins from './components/Tradeins/Tradeins.ce.vue'
registerComponent(Tradeins, 'seez-sdk-tradeins', Loader, Modal, TradeinCard, TradeinDetails)

import BuyingFlow from './components/BuyingFlow/BuyingFlow.ce.vue'
import CustomerInfo from './components/BuyingFlow/Forms/CustomerInfo.ce.vue'
import AdditionalCustomerInfo from './components/BuyingFlow/Forms/AdditionalCustomerInfo.ce.vue'
import OrderTradeIns from './components/BuyingFlow/Forms/TradeIn/OrderTradeIns.ce.vue'
import TradeInDetailCard from './components/BuyingFlow/Forms/TradeIn/TradeInDetailCard.ce.vue'
import DeliveryInfo from './components/BuyingFlow/Forms/DeliveryInfo.ce.vue'
import PaymentOptions from './components/BuyingFlow/Forms/PaymentOptions.ce.vue'
import InsuranceOptions from './components/BuyingFlow/Forms/InsuranceOptions.ce.vue'
import InsuranceOption from './components/BuyingFlow/Forms/InsuranceOption.ce.vue'
import OrderAddons from './components/BuyingFlow/Forms/OrderAddons.ce.vue'
import PricingSummary from './components/BuyingFlow/PricingSummary.ce.vue'
import OrderReview from './components/BuyingFlow/OrderReview.ce.vue'
import LegalDocumentsUpload from './components/Orders/LegalDocumentsUpload.ce.vue'
import TradeInOfferDetails from './components/BuyingFlow/Forms/TradeIn/TradeInOfferDetails.ce.vue'
import InsuranceDetails from './components/Orders/InsuranceDetails.ce.vue'
import InsuranceInfo from './components/Orders/InsuranceInfo.vue'
import FinancingDetails from './components/Orders/FinancingDetails.ce.vue'
import PaymentDetails from './components/Orders/PaymentDetails.ce.vue'
import SpaDetails from './components/Orders/SpaDetails.ce.vue'
registerComponent(BuyingFlow, 'seez-sdk-buying-flow', Loader, Modal, FormTemplate, FormTemplateAddress, PricingSummary, OrderReview, PaymentOptions, InsuranceOptions, InsuranceOption, FinancingProvider, LoanCalculator, CustomerInfo, AdditionalCustomerInfo, TradeInForm, TradeInOfferDetails, OrderTradeIns, TradeInDetailCard, DeliveryInfo, OrderAddons, LegalDocumentsUpload, InsuranceDetails, FinancingDetails, PaymentDetails, SpaDetails, TooltipComponent, InsuranceInfo)

import DeleteUser from './components/DeleteUser/DeleteUser.ce.vue'
import DeleteUserModal from './components/DeleteUser/DeleteUserModal.ce.vue'

registerComponent(DeleteUser, 'seez-sdk-delete-user', Loader, Modal, DeleteUserModal)
