<template>
  <div class="pauseOrderModal">
    <div>
      <h3>{{ t.pauseOrderModalTitle ?? 't.pauseOrderModalTitle' }}</h3>
      <span>{{ t.pauseOrderModalMessage ?? 't.pauseOrderModalMessage' }}</span>

      <div class="cancelButtons">
        <button class="keepOfferBtn" @click="emitTogglePauseOrderModal">{{ t.keepOffer ?? 't.keepOffer' }}</button>
        <button class="pauseOfferBtn" @click="emitPauseOrder">{{ t.pauseOffer ?? 't.pauseOffer' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { langMixin } from '../lang'
export default {
  name: 'PauseOrderModal',
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS')],
  emits: ['toggle-pause-order-modal', 'pause-order'],
  methods: {
    emitTogglePauseOrderModal() {
      this.$emit('toggle-pause-order-modal')
    },
    emitPauseOrder() {
      this.$emit('pause-order')
    }
  },

}
</script>
